<script setup lang="ts">
interface Props {
  value?: String | string | undefined;
  target?: '_blank' | '_self' | '_parent' | '_top' | undefined;
  href?: String | string | undefined;
  size?: 'sm' | 'md' | 'lg' | null | undefined;
  block?: boolean | undefined;
  disabled?: boolean | undefined;
  styleName?: 'outline' | 'primary' | 'inverted' | 'muted' | 'title' | null | undefined;
  className?: String | string | undefined;
  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  styleName: 'primary'
});

const emit = defineEmits(['click', 'change']);

const sizes = {
  lg: 'px-5 py-2.5',
  md: 'px-4 py-2',
  sm: 'px-2 py-1',
  null: 'rounded'
};

const styles = {
  outline: 'text-black transition-bg transition-text duration-500',
  primary: 'text-white transition-bg transition-text duration-500',
  inverted: 'text-black transition-bg transition-text duration-500',
  muted: 'text-black transition-bg transition-text duration-500',
  title: 'text-slate-500 transition-bg transition-text duration-500 hover:text-slate-900',
  null: '',
};
</script>

<template>
  <NuxtLink v-if="href" :href="href" :target="target" :class="[
    (block && 'w-full'),
    sizes[size],
    (size ? ('text-' + size + ' ' + 'rounded-' + size) : ''),
    (!disabled && styles[styleName]),
    (!disabled && 'cursor-pointer'),
    (disabled && styles['muted']),
    className
  ]" :disabled="disabled" @click="(e: Event) => (!disabled ? emit('click', e) : null)"
    @change="(e: Event) => (!disabled ? emit('change', e) : null)">
    <slot>
      {{ value }}
    </slot>
  </NuxtLink>
  <button v-else :formtarget="target" :class="[
    (block && 'w-full'),
    sizes[size],
    (size ? ('text-' + size + ' ' + 'rounded-' + size) : ''),
    (!disabled && styles[styleName]),
    (!disabled && 'cursor-pointer'),
    (disabled && styles['muted']),
    className
  ]" :disabled="disabled" @click="(e: Event) => (!disabled ? emit('click', e) : null)"
    @change="(e: Event) => (!disabled ? emit('change', e) : null)">
    <slot>
      {{ value }}
    </slot>
  </button>
</template>
